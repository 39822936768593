"use client"

import { OperatorRole } from "@deltagreen/proteus-database"
import { Button } from "@deltagreen/react-library"
import { assertUnreachable, Maybe } from "@deltagreen/utils"
import { IconAdjustments, IconHomeBolt, IconList, IconLogout, IconUser } from "@tabler/icons-react"

import { useSafeSession } from "@/components/SessionProvider"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/DropdownMenu"
import { LINKS } from "@/constants/links"
import { logoutAction } from "@/server/auth/logoutAction"

import { Navlink } from "./navigation/Navlink"

type NavigationItemsParams = {
  role: Maybe<OperatorRole>
}
const navigationItems = {
  customersOverview: {
    title: "Admin zákazníků",
    icon: <IconHomeBolt />,
    href: LINKS.customers,
  },
  operatorDashboard: {
    title: "Dispečerské rozhraní",
    icon: <IconAdjustments />,
    href: LINKS.operatorDashboard,
  },
}
function getNavigationItems(params: NavigationItemsParams) {
  if (!params.role) {
    return []
  }
  switch (params.role) {
    case "USER_MANAGER":
      return [navigationItems.customersOverview]
    case "ADMIN":
      return [navigationItems.customersOverview, navigationItems.operatorDashboard]
    case "OPERATOR":
      return [navigationItems.customersOverview]
    default:
      assertUnreachable(params.role)
  }
}

export function OperatorMenu() {
  const { session } = useSafeSession()

  const navigationItems = getNavigationItems({ role: session.user.role })

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost">
          <IconList className="h-6 w-6 text-nano-gray-700 sm:h-8 sm:w-8" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="mr-4 w-80 p-4 text-nano-gray-800">
        <DropdownMenuLabel>
          <div className="flex flex-row items-center gap-4">
            <IconUser className="h-5 w-5" />
            <span className="overflow-hidden text-ellipsis text-sm font-normal">{session.user.email}</span>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="my-4" />
        {navigationItems.length > 1 && (
          <>
            {navigationItems.map((item, index) => (
              <DropdownMenuItem key={index} className="cursor-pointer" asChild>
                <Navlink href={item.href} icon={item.icon}>
                  {item.title}
                </Navlink>
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator className="mb-4 mt-10" />
          </>
        )}

        <DropdownMenuItem className="cursor-pointer" onClick={async () => await logoutAction({})}>
          <IconLogout className="mr-4 h-6 w-6" />
          <span className="text-base">Odhlásit se</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

"use client"

import { usePathname } from "next/navigation"
import { ComponentPropsWithoutRef, forwardRef, PropsWithChildren, ReactNode } from "react"
import { tv } from "tailwind-variants"

import { NextLink } from "@/components/NextLink"
import { cn } from "@/utils/tailwind"

function useIsLinkActive(href: string) {
  const pathname = usePathname()
  return pathname === href
}

const navlinkVariants = tv({
  base: "flex rounded-md px-4 py-2 text-base text-slate-500 hover:text-slate-800",
  variants: {
    active: {
      true: "bg-gray-100 text-slate-800",
    },
    disabled: {
      true: "text-slate-400 hover:text-slate-400",
    },
  },
  defaultVariants: {
    active: false,
    disabled: false,
  },
})

type Props = {
  href: string
  icon: ReactNode
  disabled?: boolean
} & ComponentPropsWithoutRef<typeof NextLink>

export const Navlink = forwardRef<HTMLAnchorElement, PropsWithChildren<Props>>(function (props, ref) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { href, icon, children, disabled, className, ...rest } = props
  const isActive = useIsLinkActive(href)

  function renderContent() {
    return (
      <div className={cn(navlinkVariants({ active: isActive, disabled }))}>
        <div className="flex items-center gap-4">
          <div className="flex h-6 w-6 items-center justify-center text-current">{icon}</div>
          {children}
        </div>
      </div>
    )
  }

  if (disabled) {
    return renderContent()
  }

  return (
    <NextLink href={href} className="w-full no-underline" ref={ref} {...rest}>
      {renderContent()}
    </NextLink>
  )
})

"use client"

import { createContext, PropsWithChildren, useContext } from "react"

import { getServerSessionOrRedirect } from "@/server/session"

type SessionContextValue = {
  session: Awaited<ReturnType<typeof getServerSessionOrRedirect>>
}

const SessionContext = createContext<SessionContextValue | undefined>(undefined)

export function useSafeSession() {
  const ctx = useContext(SessionContext)
  if (!ctx) {
    throw new Error("SessionContext is not available")
  }

  return ctx
}

export function useTenant() {
  const ctx = useSafeSession()
  const tenantId = ctx.session.user.tenantId
  return { tenantId }
}

export function SessionProvider(props: PropsWithChildren<SessionContextValue>) {
  return <SessionContext.Provider value={{ session: props.session }}>{props.children}</SessionContext.Provider>
}

import Link from "next/link"
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react"

// by default we do NOT want to prefetch with Next.js
// there is also some problem with prefetching and broken navigation
export const NextLink = forwardRef<ElementRef<typeof Link>, ComponentPropsWithoutRef<typeof Link>>((props, ref) => {
  const { href, prefetch = false, children, ...rest } = props
  return (
    <Link ref={ref} href={href} prefetch={prefetch} {...rest}>
      {children}
    </Link>
  )
})

NextLink.displayName = "NextLink"
